
.multilevel-sidebar {
    right: 0 !important;
    left: unset !important;
}

.multilevel-sidebar-header {
    flex-direction: column;
}

#react-sidebar .sidebar-header {
    background-color: #f3f3f3;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 1rem 0.8rem;
    color: #5e5b5b;
}

.sidebar-body {
    height: calc(100vh - 48px);
    overflow-y: auto;
}

#react-sidebar .sidebar-main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px);
    overflow-y: hidden;
}

#react-sidebar .sidebar-main {
    width: 285px;
    height: calc(100vh - 48px);
    background-color: #fff;
    z-index: 99999999999999999999999;
    position: fixed;
    top: 0;
    right: 0 !important;
    left: unset !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transform: translateX(+300px);
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}
